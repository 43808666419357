import './explore.scss';

import Swiper, { EffectFade, Navigation } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/navigation';

import { getOffset, smoothScrollTo } from '../../js/helper';

class Explore {
    constructor ($element) {
        this.$explore = $element;
        // Slider
        this.$exploreSlider = this.$explore.querySelector('[data-explore-slider="slider"]');
        this.$slidePrev = null;
        this.$slideNext = null;
        this.slider = undefined;
        this.$choices = this.$explore.querySelectorAll('[data-choices-explore]');
        this.$choicesArr = [];

        this.$form = this.$explore.querySelector('[data-explore="form"]');

        // Filter
        this.$filter = null;
        this.$filterPos = 0;
        this.$exploreFilter = this.$explore.querySelector('[data-explore-filter]');

        // Tags
        this.$tags = this.$explore.querySelector('[data-explore="tags"]');
        this.$filterTags = [].slice.call(this.$tags.querySelectorAll('[data-tag-type]'));
    }

    initialize () {
        this.initFilter();

        if (this.$exploreSlider) {
            this.initSlider();
            this.initChoices();
        }

        this.setEvents();
    }

    setEvents () {
        this.$explore.addEventListener('change', () => {
            if (this.$form) {
                this.$form.submit();
            }
        });

        this.$tags.addEventListener('click', (e) => {
            if (e.target.getAttribute('data-explore-tag-remove') !== null) {
                this.removeTag(e.target, true);

                if (this.$form) {
                    this.$form.submit();
                }
            }
        });
    }

    initChoices () {
        for (let i = 0; i < this.$choices.length; i++) {
            import('../../components/form/form-choices.js').then(({ FormChoice }) => {
                const $choice = new FormChoice(this.$choices[i]);
                this.$choicesArr[this.$choices[i].getAttribute('data-explore-filter')] = $choice.initialize();
            });
        }
    }

    initSlider () {
        this.$slidePrev = this.$explore.querySelector('[data-explore-slider="prev"]');
        this.$slideNext = this.$explore.querySelector('[data-explore-slider="next"]');

        this.slider = new Swiper(this.$exploreSlider, {
            modules: [Navigation, EffectFade],
            slidesPerView: 1,
            resistanceRatio: 0,
            spaceBetween: 30,
            initialSlide: this.$filterPos,
            allowTouchMove: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 750,
            navigation: {
                prevEl: this.$slidePrev,
                nextEl: this.$slideNext
            }
        });

        setTimeout(() => {
            this.slider.update();
        }, 500);
    }

    initFilter () {
        if (this.$filterTags.length > 0) {
            smoothScrollTo(0, getOffset(this.$exploreFilter).top - 150, 0);

            if (this.$filterTags.length === 1) {
                if (this.$filterTags[0].getAttribute('data-tag-type') === 'material') {
                    this.$filterPos = 1;
                } else {
                    this.$filterPos = 0;
                }
            } else {
                this.$filterPos = 1;
            }
        }
    }

    removeTag (elem, setSelect) {
        const $delTarget = elem.closest('div');

        if (setSelect) {
            const type = $delTarget.getAttribute('data-tag-type');
            this.$choicesArr[type].setChoiceByValue('');
        }

        $delTarget.remove();
    }
}

export { Explore };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $explore = $context.querySelectorAll('[data-explore="filter"]');

        for (let i = 0; i < $explore.length; i++) {
            const $$explore = new Explore($explore[i]);
            $$explore.initialize();
        }
    }
});
